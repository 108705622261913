<template>
  <div class="groupbuy-list">
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CoreGroupbuyingsListV2',
              }"
              >團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>團購草稿</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">團購草稿</h4>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <b-button
            v-if="checkPermission([consts.CORE_GROUPBUYING_CREATE])"
            class="mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="handleCreate"
            ><i class="fa fa-plus" aria-hidden="true"></i>我要開團</b-button
          >
        </div>
      </div>
       <b-table
        striped
        hover
        responsive
        :items="groupbuys"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(groupbuying_name)="data">
          <div>{{ data.item.title }}<br>{{ data.item.groupbuying_number }}</div>
        </template>
        <template #cell(link)="data">
          <b-link :href="data.item.link" target="_blank">{{
            data.item.link
          }}</b-link>
        </template>
        <template #cell(status)="data">
          <div>{{ getStatusName(data.item.status) }}</div>
        </template>
        <template #cell(actions)="data">
            <b-button
            v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])"
            class="ml-2"
            variant="primary"
            :to="{
              name: 'CoreGroupbuyingsViewV2',
              params: {
                groupbuyID: data.item.id,
              },
            }"
            >查看</b-button
          >
          <b-button
            v-if="checkPermission([consts.CORE_GROUPBUYING_MODIFY])"
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'CoreGroupbuyingsEditV2',
              params: {
                groupbuyID: data.item.id,
              },
            }"
            >編輯</b-button
          >

          <b-button
            v-if="checkPermission([consts.CORE_GROUPBUYING_MODIFY])"
            class="ml-2"
            variant="inverse-danger"
            @click="handleDelete(data.item)"
            >刪除</b-button
          >
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          @change="handleChangePage()"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapState } from "vuex";
import groupbuyApi from "@/apis/groupbuy";
import checkPermission from "@/mixins/checkPermission";
import PermissionChecker from "@/utils/PermissionChecker";
import { statusMapping, getStatus } from "./utils";

export default {
  mixins: [checkPermission],
  components: {},
  data() {
    return {
      isFetchGroupbuyList: false,
      keyword: "",
      isLoading: false,
      groupbuys: [],
      fields: [
        {
          key: "groupbuying_name",
          label: "團購名稱/編號",
        },
        {
          key: "name",
          label: "商品名稱/編號",
        },
        {
          key: "",
          label: "商品圖片",
        },
        {
          key: "",
          label: "單價",
        },
        {
          key: "status",
          label: "團購狀態",
        },
        {
          key: "start_at",
          label: "開始時間",
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        {
          key: "end_at",
          label: "截止時間",
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      breadcrumb: [
        {
          text: "團購管理",
          href: "#",
        },
        {
          text: "團購草稿",
          href: "#",
          active: true,
        },
      ],
      perPageOptions: [10, 50, 100],
      currentTabIndex: 0,
      perPage: 10,
      currentPage: 1,
      total: 0,
      query: {
        start_at: "",
        end_at: "",
      },
    };
  },

  created() {
    this.getCoreGroupbuyList();
  },

  watch: {
    $route() {
      this.getCoreGroupbuyList();
    },
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },

  methods: {
    format,
    async getCoreGroupbuyList() {
      const { keyword } = this.$route.query || {};
      if (this.keyword && !keyword) {
        this.$router.push({
          query: {
            keyword: this.keyword,
          },
        });
      }
      try {
        this.isFetchGroupbuyList = true;
        let params = {
          keyword: this.keyword ? this.keyword : "",
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          status: '8',
        };

        const { data } = await groupbuyApi.getCoreGroupbuyList(params);
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        data.data.forEach(groupbuy => {
           groupbuy.name =  groupbuy.product?  `${groupbuy.product?.title} (${groupbuy.product?.sale_page_code})` : '';
        });


        this.groupbuys = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetchGroupbuyList = false;
    },
    async handleDelete(item) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await groupbuyApi.coreDeleteGroupbuying(
                item.id
              );
              if (response.status === 200) {
                this.getCoreGroupbuyList()
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                })
              }
              this.isLoading = true;
            } catch (error) {
               this.$swal("刪除失敗", error.response.data.message, "error");
            }
            this.isLoading = false;
          }
        });
    },

    handleCreate() {
      this.$router.push({ name: "CoreGroupbuyingsCreateV2" });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    handleChangePage() {
      this.$nextTick(() => {
        this.getCoreGroupbuyList();
      });
    },
    getStatusName(status) {
      return statusMapping[getStatus(status)];
    },
  },
};
</script>

<style lang="scss">
.groupbuy-list {
  .nav-tabs .nav-link.active,
  .table thead {
    background: rgba(255, 98, 88, 0.2);
  }
}
</style>
